@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import '../../../../libs/common/src/ui/index.css';

@import 'tailwindcss/utilities';

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
input::-webkit-date-and-time-value {
  text-align: left;
}
